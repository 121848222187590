import { Query } from '@datorama/akita';
import {Injectable} from '@angular/core';
import {UserState, UserStore} from './user.store';

@Injectable({ providedIn: 'root' })
export class UserQuery extends Query<UserState> {

  selectPhotoURL$ = this.select('photoURL');
  selectDisplayName$ = this.select('displayName');
  selectSchedule$ = this.select('schedule');
  selectIsProfileCompleted$ = this.select('isProfileCompleted');
  selectEmail$ = this.select('email');
  selectUserType$ = this.select('type');
  selectCalendar$ = this.select('calendar');
  socialProfiles$ = this.select('socialProfiles');
  selectedLanguage$ = this.select('selectedLanguage');

  constructor(protected override store: UserStore) {
    super(store);
  }
}
