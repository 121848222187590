import { Store, StoreConfig } from '@datorama/akita';
import {Injectable} from '@angular/core';
import {ISchedule} from '../../models/schedule/ISchedule';
import {SubscriptionPlansEnum} from '../../models/subscriptions-plan/subscription-plans.enum';
import {SubscriptionPeriodEnum} from '../../models/subscriptions-plan/subscription-period.enum';
import { IHoliday } from '../../models/calendar/holiday.interface';

export interface ISocialProfiles {
  facebook?: string;
  instagram?: string;
  youtube?: string;
  twitter?: string;
  linkedin?: string;
}

export interface UserState {
  isLoggedIn?: boolean;
  displayName?: string;
  email?: string;
  emailVerified?: boolean;
  autoAppointments?: boolean;
  isProfileCompleted?: boolean;
  phoneNumber?: string;
  photoURL?: string | null;
  type: string;
  uid: string;
  updatedAt: string;
  createdAt: string;
  limitMaxServices?: number;
  limitMaxBookings?: number;
  bookingInterval?: number;
  bio?: string;
  expertise?: string;
  website?: string;
  languages?: string[];
  birthdate?: string;
  gender?: string;
  entityType?: string;
  subscription?: {
    plan: SubscriptionPlansEnum,
    period: SubscriptionPeriodEnum,
  };
  calendar?: {
    holidays: IHoliday[]
  }
  location?: {
    address: string,
    city: string,
    country: string,
    lng: number,
    lat: number,
    state: string;
    postalCode: string
  };
  schedule?: {
    Monday: Array<ISchedule>,
    Tuesday: Array<ISchedule>,
    Wednesday: Array<ISchedule>,
    Thursday: Array<ISchedule>,
    Friday: Array<ISchedule>,
    Saturday: Array<ISchedule>,
    Sunday: Array<ISchedule>,
  };
  socialProfiles?: ISocialProfiles;
  name?: string;
  claims: string[];
  payments?: any[];
  services: {id: string, ref: string}[];
  vatNumber?: string;
  hasReachedMaxServices?: boolean;
  hasReachedMaxAppointments?: boolean;
  selectedLanguage: string;
  stripe?: string;
  stripeId?: string
}

export function createInitialState(): UserState {
  return {
    isLoggedIn: false,
    createdAt: '',
    displayName: '',
    email: '',
    emailVerified: false,
    autoAppointments: false,
    phoneNumber: '',
    photoURL: '',
    type: '',
    uid: '',
    updatedAt: '',
    bio: '',
    expertise: '',
    website: '',
    languages: [],
    birthdate: '',
    gender: '',
    location: {
      address: '',
      city: '',
      country: '',
      lng: 0,
      lat: 0,
      state: '',
      postalCode: '',
    },
    claims: [],
    name: '',
    services: [],
    payments: [],
    vatNumber: '',
    selectedLanguage: 'ro',
    hasReachedMaxServices: null,
    hasReachedMaxAppointments: null,
    limitMaxBookings: null,
    limitMaxServices: null,
  };
}
@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'user', resettable: true })
export class UserStore extends Store<UserState> {
  constructor() {
    super(createInitialState());
  }
}
